import React from "react";
let slovillaApi = require("./lib/slovilla_api.js");

	
/* Main React Class */
class Login extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			login       : "",
			password    : "",
			remember    : "on",
			loginError  : "none",
			loginOK 	: "none",
			loginCheck  : "none",
			reloadPage : this.props.reloadPage		
		};	
		
		this.checkLogin = this.checkLogin.bind(this);		
		this.setLogin = this.setLogin.bind(this);		
		this.setPassword = this.setPassword.bind(this);		
		this.setRemember = this.setRemember.bind(this);			
	}	

	/*
	 * Function for the load additional events
	 */		
	checkLogin() {
		var self = this;
		
		// Login and Password should be entered
		if ((this.state.login === "") || (this.state.password === "")) {
			alert("Veuillez saisir un utilisateur et un mot de passe");	
			return;
		}
		
		// Display waiting messages
		self.setState({
			loginError : "none",
			loginOK    : "none",
			loginCheck : "block"
		});		
		
		let headers = {login: true};
		let params = {login:  this.state.login, password:  this.state.password, remember:  this.state.remember};			
		slovillaApi.request("post", "/auth", headers, params).then( function (result) {				
			// console.log(result);
			
			/* If authentication failed then skip it */
			if (result.status === false) {
				self.setState({
					loginError : "block",
					loginOK    : "none",
					loginCheck : "none"
				});
				return;				
			}
			
			/* Else reload the page with the cookie */
			if (self.state.remember === "on") {
				let expireDate = new Date();
				expireDate.setTime(eval(expireDate.getTime() + 6*30*24*3600*1000));
				document.cookie = "username=" + result.cookie + "; expires= " + expireDate.toUTCString() + ";path=/";
			} else {
				document.cookie = "username=" + result.cookie + "; expires= 0" + ";path=/";
			}
			
			self.setState({
				items : result,
				loginOK : "block",
				loginError : "none",
				loginCheck : "none"
			});
			
			/* Reload the Page */
			let newState = {}
			// newState.view = "itemList";
			self.state.reloadPage(newState);
			
		});
	}
	
	setLogin(event) {
		this.setState({
			login : event.target.value
		});				
	}	

	setPassword(event) {
		this.setState({
			password : event.target.value
		});				
	}

	setRemember(event) {
		this.setState({
			remember : event.target.value
		});				
	}		
		
	/*
	 * Render One Item Function
	 */		
	render(){	
		return (
			<div className="login">
				<h1>Se connecter</h1>
				<input type="text" placeholder="Utilisateur" onChange={this.setLogin}/>
				<input type="password" placeholder="Mot de passe" onChange={this.setPassword}/>
				<label>
					<input type="checkbox" style={{ width : "auto" }} onChange={this.setRemember} checked />
					<p>Se souvenir de moi</p>
				</label>
				<button onClick={this.checkLogin}>Se connecter</button>
				<div style={{display : this.state.loginError }} className="loginError">Utilisateur et/ou mot de passe incorrects</div>
				<div style={{display : this.state.loginOK }} className="loginOK">Un instant, chargement des articles ...</div>
				<div style={{display : this.state.loginCheck }} className="loginCheck">Vérification en cours. Un instant s'il vous plait ...</div>				
			</div>		
		);
	}	
}

export default Login;