
/* 
 * Export Modules functions
 **/
module.exports =  {


	/* 
	 * Function to concatenate JSON
	 **/
	jsonConcat: function(o1, o2) {
		for (var key in o2) {
			o1[key] = o2[key];
		}
		return o1;
	},

	/* 
	 * Function to get a cookie - https://www.w3schools.com/js/js_cookies.asp
	 **/
	getCookie: function(cname) {	 
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(";");
		for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

};	