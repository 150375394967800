/* 
 * Define Global variables
 **/
 
var axios = require("axios");
var commonFunction = require("./common_module.js");

var api_url = "https://genealogy-api.hemar.fr";
var client_id = "4AB8123FFCD3EFC871FFABC34123";


function deleteCookie( ) {
	console.log("Deleting OAuth Cookie");
	document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

/* 
 * Function to build Params for Axios Call
 **/
function buildParams(apiHeaders, apiParams) {
  
	return new Promise( function (resolve)  {

		// Add Token Auth in the header
		var apiHeadersConcatenated = {};
		var access_token = commonFunction.getCookie("access_token");

		if ((apiHeaders.login === undefined) && (access_token === "")) {
			// console.log('Access Token not found in the cookie. Request a new one ...');	
			module.exports.setAccessToken().then( function (access_token) {
				var auth_header = {}; 
				auth_header.Authorization = `Bearer ${access_token}`;
				// auth_header.Content-Type = "application/json";	
			
				apiHeadersConcatenated = commonFunction.jsonConcat(apiHeaders, auth_header);
				delete apiHeadersConcatenated.login;
				
				resolve( {	params:	apiParams, headers:	apiHeadersConcatenated, withCredentials : true });
			});
		} else {
			var auth_header = {}; 
			auth_header.Authorization = `Bearer ${access_token}`;
			// auth_header.Content-Type = "application/json";			
			
			apiHeadersConcatenated = commonFunction.jsonConcat(apiHeaders, auth_header);
			delete apiHeadersConcatenated.login;
			
			// console.log(apiParams);
			// console.log(apiHeadersConcatenated);
			// console.log('Header = ' + access_token);
			// Then return the Json
			resolve( {	params:	apiParams, headers:	apiHeadersConcatenated, withCredentials : true });
		}
	});
}


/* 
 * Function to build Multiple Params for Axios Call (same as above but for multipleRequest)
 **/
function buildMultipleParams(options) {
  
	return new Promise( function (resolve)  {

		// Add Token Auth in the header
		var access_token = commonFunction.getCookie("access_token");
			
		if (access_token === "") {
			module.exports.setAccessToken().then( function (access_token) {
				var auth_header = {}; //"{\"Authorization\":\"" + access_token + "\"}"; 
				auth_header.Authorization = `Bearer ${access_token}`;
				// auth_header.Content-Type = "application/json";
				
				for (let i = 0; i < options.length; i++) {
					options[i].headers = commonFunction.jsonConcat(options[i].headers, auth_header);
					options[i].withCredentials = true;
				}		
				
				resolve(options);
			});
		} else {
			var auth_header = {}; //"{\"Authorization\":\"" + access_token + "\"}"; 
			auth_header.Authorization = `Bearer ${access_token}`;
			// auth_header.Content-Type = "application/json";
				
			for (let i = 0; i < options.length; i++) {
				options[i].headers = commonFunction.jsonConcat(options[i].headers, auth_header);
				options[i].withCredentials = true;				
			}
			resolve(options);
		}
	});
}

/* 
 * Export Modules functions
 **/
module.exports =  {

	/*
	 * Set Access Token in Cookie
	**********************************************************/
	setAccessToken: function() {
		
		return new Promise( function (resolve, reject)  {
			
			var params = {
				withCredentials: true  
			};			
			
			// Get Username Token
			var username = commonFunction.getCookie("username");
			
			axios.get(api_url + "/implicit_authorize?client_id="+client_id+"&redirect_uri=&scope=&response_type=token&state=&username="+username, params)
				.then(function (response) {
					// handle success
					// console.log(response.data);
					// console.log("Token Expired. Get New Token and store it in a cookie");
					var expireDate = new Date();
					expireDate.setTime(eval(expireDate.getTime() + 1000*response.data.expires_in - 5*60*1000));
					// console.log(expireDate.toLocaleString());
					// console.log("access_token=" + response.data.access_token + "; expires= " + expireDate.toUTCString());
					document.cookie = "access_token=" + response.data.access_token + "; expires= " + expireDate.toUTCString() + ";path=/";
					resolve(response.data.access_token);
				})
				.catch(function (error) {
					console.log("Error while getting API access token : " + error);
					reject();
				});
		});
	},	
	
	/*
	 * API Request Wrapper
	**********************************************************/
	request: function(method, path,	headers, params) {
		
		return new Promise( function (resolve, reject)  {
		
			// Switch according to the method	
			switch (method) {
			case "get":
				// console.log('Call Axios');
				buildParams(headers, params).then ( function (result) {
					// console.log('Built Params ' + JSON.stringify(result));
					axios.get(api_url + path, result)
						.then(function (response) {
							// console.log('Response Data: ' + JSON.stringify(response.data));
							resolve(response.data);
						})
						.catch(function (error) {
							console.log("Error while calling " + path + "(" + method + "): " + error);
							deleteCookie();
							reject();
						});
				});	
				break;
			case "post":
				// console.log('Call Axios');
				buildParams(headers, params).then ( function (result) {
					// console.log('Built Params ' + JSON.stringify(result));
					axios.post(api_url + path, { params : result.params } , { headers : result.headers } )
						.then(function (response) {
							// console.log('Response Data: ' + JSON.stringify(response.data));
							resolve(response.data);
						})
						.catch(function (error) {
							console.log("Error while calling " + path + "(" + method + "): " + error);
							deleteCookie();
							reject();
						});
				});	
				break;		
			case "put":
				buildParams(headers, params).then ( function (result) {
					axios.put(api_url + path, { params : result.params } , { headers : result.headers } )
						.then(function (response) {
							resolve(response.data);
						})
						.catch(function (error) {
							console.log("Error while calling " + path + "(" + method + "): " + error);
							deleteCookie();
							reject();
						});
				});	
				break;					
			default:
				console.log("Method not recognized " + method + ".");
				reject();
			}
		});

	},
	
	/*
	 * API Multiple Request Wrapper
	**********************************************************/
	multipleRequest: function(options) {
		
		return new Promise( function (resolve, reject)  {
			
			// console.log("Options" + JSON.stringify(options));
			buildMultipleParams(options).then ( function (enrichedOptions) {
				// console.log("Options" + JSON.stringify(enrichedOptions));
				
				// Create the configuration
				let promises = [];
				for (let i = 0; i < enrichedOptions.length; i++) {
					enrichedOptions[i].baseURL = api_url;
					promises.push(axios.request(enrichedOptions[i]));
				}
			
				// Then Call Axios
				axios.all(promises).then(axios.spread((...args) => {
					let output = [];
					for (let i = 0; i < args.length; i++) {
						output[i] = args[i].data;
					}					
					resolve(output);					
				}))
				.catch(function (error) {
					console.log("Error while calling " + options + " : " + error);
					reject();
				});
			});
		});

	},	
	
	/*
	 * Log a message
	**********************************************************/
	log: function(app, level, message) {
		
		return module.exports.request ("post", "/log",	{}, { "level" : level, "message" : message, "app" : app } );

	}	

};	
