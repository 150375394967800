import React, { Component } from 'react';
import './App.css';
import Login from './Login.js';
let slovillaApi = require("./lib/slovilla_api.js");
let commonFunction = require("./lib/common_module.js");

class App extends Component {

	constructor(props) {
		let username = commonFunction.getCookie("usernameFull"); 
		let view = "";
		
		super(props);

		/*
		 * Get the username cookie for authentification
		 **/
		let login = commonFunction.getCookie("username"); 
		let path = "";
		if (login === "") {
			path = "/login"
		} else {
			path = window.location.pathname;		
		}
			
		/*
		 * Get the view to be displayed
		 **/
		let pathes = path.split('/');
		console.log("Route " + pathes[1]);
		switch (pathes[1]) {
			case 'login':
				view = "login";
				break;			
			default:
				view = "itemList";
		}		

		/*
		 * Init the State
		 **/
		this.state = {
			username : username,
			view : view,
			admin    : null
		};
		
	}
	
	/*
	 * Reload the Page
	 */		
	reloadPage(newState) {
		
		/* Compute the View if not specified */
		if (newState.view === undefined)  {
			let pathes = window.location.pathname.split('/');
			switch (pathes[1]) {
				case 'login':
					newState.view = "login";
					break;			
				default:
					newState.view = "tree";
			}	
		}	
		
		
		/*
		 * Update the View
		 **/		
		this.setState({
		    ...this.state,
    		...newState
		});	
	
	}	

 
	/*
	 * Disconnect the User
	 */	
	disconnect() {
		document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		document.cookie = "usernameFull=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";	
		
		document.location.reload();
		window.location.replace("/");
	}	
 
	render() {
		let main = [];

		/*
		 * Display the right view
		 **/
		console.log("view " + this.state.view);
		switch (this.state.view) {
			case 'login':
				main.push(
					<div key="loginView"> 
						<Login reloadPage={this.reloadPage.bind(this)} />
					</div>
				);			
				break;			
			default:
				main.push(<div key="defaultView">
						Chargement en cours ...
					</div>
				);				
		}

		/*
		 * Get the Welcome / Header Message
		 **/
		let welcomeMsg = ""; 
		if (this.state.username === "") {
			welcomeMsg = "Pour continuer, j'ai besoin de vous connaitre..."
		} else {
			welcomeMsg = "Bonjour "+ this.state.username;
		}
		
		/*
		 * Render the Page
		 **/		 
		return (
		  <div className="App">	
			<div className="appTop" onClick={this.disconnect}>
				{welcomeMsg}
			</div>
			
			<a href="/">
				<div className="appHeader">
					<h1>Généalogie</h1>
					<h2>les ancêtres de la famille</h2>
				</div>	
			</a>	
			<div className="appBody">	
				{main}
			</div>
		  </div>
		);
	}
}

export default App;
